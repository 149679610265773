/** @typedef {{id: string, text: string}} PageLoadingIndicator */
/** @typedef {{status: string, info: string, buttontext: string, headline: string}} IndicationModal */

/**
 * @typedef {Object} ConfirmModal
 * @property {string} id
 * @property {string} headline
 * @prop {number} infotext
 * @property {callback} callbackConfirmed
 * @prop {string} [callbackConfirmedText]
 * @prop {callback} [callbackDenied]
 * @prop {string} [callbackDeniedText
 * @prop {boolean} [isDelete]
 */

import axios from "axios";
import { defineStore } from "pinia";

import router from "@/router";
import { getTraceIdString } from "@/services/app.js";
import { openAPIFactory } from "@/services/open-api.js";

export const useAppStore = defineStore("app", {
  state: () => ({
    runtimeEnvironment: {
      k1: {
        url: "/api/cockpit/",
      },
      masterportal: {
        url_erfassung: "/masterportal/erfassung/",
        url_detail: "/masterportal/detail/",
        url_planwerke: "/masterportal/planwerke/",
      },
    },
    /** @type {PageLoadingIndicator[]} */
    pageLoadingIndicators: [],
    /** @type {Array} */
    popOvers: [],
    /** @type {IndicationModal[]} */
    indicationModals: [],
    /** @type {ConfirmModal[]} */
    confirmModals: [],
    hasUserLoadingError: false,
    /* Dictionary for loaded services */
    /**  @type {{[key: string]: boolean}} */
    resolved: {
      "/nutzer": false, // getNutzer: Nutzerinformationen
      "/verfahren": false, // getAllVerfahren: Liefert die Metadaten zu allen Verfahren, die ich sehen darf (abonniert habe).
      "/nutzer/verfahren": false, // getNutzerVerfahren: Verfahren IDs der abonnierten Verfahren
      "/konfigurationen/codelisten": false, // getAllCodelisten: Liefert alle Codelisten aus
      "/konfigurationen/verfahren": false, // getAll: Liefert(alle) VerfahrenKonfigurationen
      "/konfigurationen/blacklist": false, // liefert die Blackliste aus
    },
  }),
  actions: {
    /**
     * Loads and caches infos about the runtime environment.
     */
    loadRuntimeEnvironment() {
      axios
        .get("/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.runtimeEnvironment = response.data;
        })
        .catch(() => {
          console.error("Failed to load configuration file 'config.json'");
          alert("Could not load 'config.json'");
        });
    },
    /**
     * Loads information on the version of K1.
     * @returns {void}
     */
    async loadK1Version() {
      await openAPIFactory
        .versionResourceApiFactory()
        .getVersionInfo()
        .then((response) => {
          this.k1Version = response.data;
        })
        .catch((error) => {
          this.showErrorModal({
            response: error,
            customErrorMessage: "Abfrage der K1-Version fehlgeschlagen!",
          });
        });
    },
    /**
     * Shows an indication modal containing the composed error message.
     * @param {Object} payload
     * @returns {void}
     */
    async showErrorModal(payload) {
      let errorHeadline = "Unerwarteter Fehler";
      let errorMessage = "Schwerwiegender Fehler aufgetreten.";
      const responseStatus = payload.response.response?.status;

      if (this.resolved["/nutzer"] === false) {
        // if error exists show reason in console
        console.log(payload.customErrorMessage, " Ursache: ", payload.response.message);

        if (responseStatus !== undefined && responseStatus >= 500 && responseStatus < 600) {
          router.replace({ name: "ServerError" });
          return;
        }
      }

      if (responseStatus === 403) {
        router.replace({ name: "AppAccessDenied" });
        return;
      }

      if (typeof payload.response === "object") {
        let parsedResponse = undefined;

        // if response data is a blob turn it into JSON Object
        if (payload.response?.response?.data instanceof Blob) {
          await payload.response.response.data.text().then((value) => {
            parsedResponse = { response: { data: JSON.parse(value) } };
          });
        }

        if (!parsedResponse) {
          parsedResponse = payload.response;
        }

        // add error headline from http status codes
        if (responseStatus === 400) {
          errorHeadline = "Ungültige Eingabedaten";
        } else if (responseStatus === 500) {
          errorHeadline = "Server Fehler";
        } else if (responseStatus === 404) {
          errorHeadline = "Seite nicht gefunden";
        } else if (responseStatus === 401) {
          errorHeadline = "Fehlende Authentifizierung";
        }

        // add error message from http response
        if (parsedResponse?.response?.data?.humanMessage) {
          if (parsedResponse?.response?.data?.message) {
            errorMessage = "Fehlermeldung: " + parsedResponse.response.data.message;
          } else if (parsedResponse.data?.message) {
            errorMessage = "Fehlermeldung: " + parsedResponse.data.message;
          } else if (parsedResponse.data?.statusText) {
            errorMessage = "Fehlermeldung: " + parsedResponse.data.statusText;
          } else if ("status" in parsedResponse && "statusText" in parsedResponse) {
            errorMessage =
              "Fehlercode: HTTP/" +
              parsedResponse.status +
              ", Fehlermeldung: " +
              parsedResponse.statusText;
          } else if ("message" in parsedResponse) {
            errorMessage = "Fehlerbeschreibung: " + parsedResponse.message;
          }
        }
      }

      // add custom error message from application
      if (typeof payload.customErrorMessage === "string") {
        errorMessage =
          "<span class='error'>" + payload.customErrorMessage + "</span><br>" + errorMessage;
      }

      const traceIdString = getTraceIdString(payload.response || payload);

      this.showIndicationModal({
        status: "error",
        info: errorMessage + traceIdString,
        buttontext: "Schließen",
        headline: errorHeadline,
      });
    },
    /**
     * Shows an indication modal.
     * @param {IndicationModal} payload
     *   The modal's content. Must feature the properties "headline", "info", "status" and "buttontext".
     * @returns {void}
     */
    showIndicationModal(payload) {
      this.indicationModals = [...this.indicationModals, payload];
    },
    /**
     * Shows an indication modal.
     * @param {ConfirmModal} payload
     *   The modal's content. Must feature the properties "headline", "info", "status" and "buttontext".
     *
     *
     *
     *
     * @returns {void}
     */
    showConfirmModal(payload) {
      this.confirmModals = [...this.confirmModals, payload];
    },

    /**
     * Shows a page loading indicator.
     * @param {PageLoadingIndicator} payload
     *   The definition of the page loading indicator to display.
     * @returns {void}
     */
    showPageLoadingIndicator(payload) {
      this.pageLoadingIndicators = [...this.pageLoadingIndicators, payload];
    },
    /**
     * Hides a specific page loading indicator.
     * @param {String} id
     *   The ID of the page loading indicator that will be hidden.
     * @returns {void}
     */
    hidePageLoadingIndicator(id) {
      this.pageLoadingIndicators = this.pageLoadingIndicators.filter((bar) => bar.id !== id);
    },
  },
  getters: {
    isResolvedRoute() {
      // compute loading status from meta-data for this page
      const resolve = router.currentRoute.value.meta.resolve ?? {};

      return Object.keys(resolve).reduce((result, value) => result && this.resolved[value], true);
    },
  },
});
