<script>
  export default {
    name: "ThePageFooter",
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
    },
    methods: {
      handleRouteClick(routePath) {
        if (localStorage.isLogoutVisible) {
          localStorage.setItem("selectedRoute", routePath);
        }
      },
    },
  };
</script>

<template>
  <footer class="dpw-page-footer">
    <div class="dpw-page-footer__wrapper">
      <span>© {{ currentYear }} Behörde für Stadtentwicklung und Wohnen Hamburg</span>
      <div class="d-flex gap-4">
        <router-link to="/impressum" title="Impressum" @click="handleRouteClick('/impressum')"
          >Impressum</router-link
        >
        <router-link to="/datenschutz" title="Datenschutz" @click="handleRouteClick('/datenschutz')"
          >Datenschutz</router-link
        >
        <router-link
          to="/barrierefreiheit"
          title="Barrierefreiheit"
          @click="handleRouteClick('/barrierefreiheit')"
          >Barrierefreiheit</router-link
        >
        <router-link
          to="/barrierefreiheit#barriere-melden"
          title="Barriere melden"
          @click="handleRouteClick('/barrierefreiheit#barriere-melden')"
          >Barriere melden</router-link
        >
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
  @import "@/styles/imports";

  .dpw-page-footer {
    margin-top: auto; // position footer at bottom for short content

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 72px;
      background: var(--dps-color-background);
      padding: 0 70px;
      gap: 8px;
      border-top: 1px solid #eaebed;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    span,
    a {
      color: var(--dps-color-black);
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }

    a:hover,
    a:focus,
    a:active {
      color: var(--dps-color-primary-hover);
    }

    .has-fixed-footer & {
      margin-top: 0;
    }
  }
</style>
