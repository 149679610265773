<script>
  import { mapStores } from "pinia";

  import AppLoadingSpinner from "@/components/AppLoadingSpinner.vue";
  import { useAppStore } from "@/stores/app.js";

  export default {
    name: "ThePageLoadingIndicator",
    components: {
      AppLoadingSpinner,
    },
    computed: {
      ...mapStores(useAppStore),
      activePageLoadingIndicators() {
        return this.appStore.pageLoadingIndicators;
      },
      isOpen() {
        return this.activePageLoadingIndicators.length !== 0;
      },
      infoText() {
        return this.activePageLoadingIndicators[this.activePageLoadingIndicators.length - 1].text;
      },
    },
  };
</script>

<template>
  <Transition name="progressbar">
    <div
      v-if="isOpen"
      class="dpw-page-loading-indicator container-fluid d-flex flex-column justify-content-center align-items-center h-100 w-100"
    >
      <AppLoadingSpinner />
      <span class="mt-3 progressbar-text" role="status" aria-hidden="true">{{ infoText }}</span>
    </div>
  </Transition>
</template>

<style lang="scss">
  .dpw-page-loading-indicator {
    background: var(--dps-color-black-70);
    position: fixed;
    top: 0;
    left: 0;
    padding: 10%;
    z-index: 9999999;
    color: var(--dps-color-white);
  }

  .progressbar-enter-active,
  .progressbar-leave-active {
    transition: opacity 0.3s ease;
  }

  .progressbar-enter-from,
  .progressbar-leave-to {
    opacity: 0;
  }

  .progressbar-enter-to,
  .progressbar-leave-from {
    opacity: 0.7;
  }
</style>
