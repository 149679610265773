/**
 * @typedef {import('../services/open-api/models/verfahrens-konfiguration-rest.js').VerfahrensKonfigurationRest } Verfahrenskonfiguration
 * @typedef {import('../services/open-api/models/verfahrensteilschritt-rest.js').VerfahrensteilschrittRest } Verfahrensteilschritt
 * @typedef {import('../services/open-api/models/system-parameter-rest.js').SystemParameterRest } SystemParameter
 * @typedef {import('../services/open-api/models/rechtszitat-detail-rest.ts').RechtszitatDetailRest } Rechtszitat

 * @typedef {import('../services/open-api/models/zeitraum-rest.js').ZeitraumRest } Zeitraum
 * @typedef {import('../services/open-api/models/whitelist-termin-rest.js').WhitelistTerminRest } WhitelistTermin
 *
 * @typedef {import('../services/open-api/models/codeliste.js').Codeliste } Codeliste
 * @typedef {import('../services/open-api/models/code-zustaendigkeit.ts').CodeZustaendigkeit } CodeZustaendigkeit
 * @typedef {import('../services/open-api/models/code-verfahrensschritt.ts'').CodeVerfahrensschritt } CodeVerfahrensschritt
 * @typedef {{
 *   name: string;
 *   verfahrensteilschritte?: {
 *     [key: string]: VerfahrensteilschrittKonfiguration;
 *   }
 * }} VerfahrensschrittKonfiguration
 * @typedef { { customProceedingOrder: string[], sortingOrder: string} } CockpitSettings
 * @typedef { {nutzerID: string,
 *             vollerName: string,
 *             email: string,
 *             rechte: string[],
 *             rolle:string[],
 *             cockpitSettings: CockpitSettings} } UserSettings
 *
 * @typedef {Verfahrenskonfiguration | undefined} RawProceedingStructure
 * @typedef {{codeVerfahrensteilschritt: string, verfahrensteilschritte: Verfahrensteilschritt[]}} Verfahrensteilschritte
 * @typedef {{name: string, verfahrensschritte:   verfahrensschritte: {
 *     [key: string]: {
 *       name: string;
 *       verfahrensteilschritte: {
 *         [key: string]: VerfahrensteilschrittKonfiguration;
 *       };
 *     }}} ProceedingStructure
 * @typedef {{code: string, name: string, additional: string, parent: string}} CodeDescription
 *
 */

import moment from "moment";
import { defineStore } from "pinia";

import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.js";

export const useFundamentalStore = defineStore("fundamental", {
  /**
   * @typedef { {codelists: {[key: string]: CodeDescription} | undefined,
   *                    rawProceedingStructures: RawProceedingStructure[] | undefined,
   *                    proceedingStructures: ProceedingStructure[] | undefined,
   *                    userSettings: UserSettings | undefined,
   *                    systemParameters: [SystemParameter] | undefined,
   *                    showAllSystemParameters: boolean,
   *                    rechtszitate: [Rechtszitat] | undefined,
   *                    dateBlacklist: [Zeitraum] | undefined,
   *                    dateWhitelist: [WhitelistTermin] | undefined }} FundamentalState
   *
   * @type {FundamentalState} state
   */
  state: () => ({
    /** @type {{[key: string]: CodeDescription}} */
    codelists: {},
    /** @type {RawProceedingStructure[]} */
    rawProceedingStructures: [],
    /** @type {ProceedingStructure[]} */
    proceedingStructures: [],
    /** @type {UserSettings} */
    userSettings: {
      currentProceedingView: {},
      sortingOrder: "",
      customProceedingOrder: [],
      rechte: [],
    },
    /** @type {[SystemParameter]} */
    systemParameters: [],
    /** @type boolean */
    showAllSystemParameters: true,
    /** @type {[Rechtszitat]} */
    rechtszitate: [],
    /** @type {[Zeitraum] | undefined} */
    dateBlacklist: undefined,
    /** @type {[WhitelistTermin]} */
    dateWhitelist: [],
    /** @type {Array} */
    k1Version: [],
    /** @type {Array<WhitelistTerminRest>} */
    whiteListData: [],
    /** @type {Array<WhitelistTerminRest>} */
    whiteListSeriesData: [],
  }),
  actions: {
    /**
     * Loads and caches XBau codelists.
     * @returns {Promise<any>}
     */
    loadConfigurationCodelists() {
      const appStore = useAppStore();

      return new Promise((resolve) => {
        if (appStore.resolved["/konfigurationen/codelisten"] === false) {
          openAPIFactory
            .codelistResourceApiFactory()
            .getAllCodelisten()
            .then((response) => {
              this.codelists = {};

              response.data.forEach((codelist) => {
                this.codelists[codelist.name.substring(5).toLowerCase()] = codelist.enumeration
                  .reduce(
                    (acc, cur) =>
                      acc.concat({
                        code: cur.code,
                        name: cur.name,
                        additional:
                          cur.attributes.attribute.find(
                            (x) => x.name === "wertZweiteBeschreibungsspalte",
                          )?.value ?? "",
                        parent:
                          cur.attributes.attribute.find((x) => x.name === "bezirk_id")?.value ?? "",
                      }),
                    /** @type CodeDescription[] */
                    [],
                  )
                  // to sort lists for Bezirke, Gebäudearten, Wettbewerbsarten by code (e.g 0001, 0002, 0003)
                  .sort((a, b) => a.code - b.code);
              });

              appStore.resolved["/konfigurationen/codelisten"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Abfrage der Codelisten-Konfiguration fehlgeschlagen!",
              });
            });
        } else {
          resolve();
        }
      });
    },
    /**
     * Loads and caches basic proceeding structures.
     * @returns {Promise<false | VerfahrensKonfigurationRest[]>}
     */
    loadProceedingStructures() {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        if (!appStore.resolved["/konfigurationen/verfahren"]) {
          openAPIFactory
            .verfahrensKonfigurationRessourceApiFactory()
            .getAll()
            .then((response) => {
              this.rawProceedingStructures = response.data;

              const proceedingStructures = {};

              response.data.forEach((proceedingtype) => {
                if (
                  proceedingtype?.verfahrenssteuerung?.code &&
                  proceedingtype?.verfahrenssteuerung?.name
                ) {
                  const vsObj = {};

                  if (proceedingtype?.verfahrensschritte) {
                    for (const vs of proceedingtype.verfahrensschritte) {
                      const vtsObj = {};
                      let stepIndex = 1;

                      if (vs.verfahrensteilschritte) {
                        for (const vts of vs.verfahrensteilschritte) {
                          if (
                            vts.codeVerfahrensteilschritt?.code &&
                            vts.codeVerfahrensteilschritt?.name &&
                            vs.codeVerfahrensschritt
                          ) {
                            vtsObj[vts.codeVerfahrensteilschritt.code] = {
                              name: vts.codeVerfahrensteilschritt.name,
                              codeVerfahrensschritt: vs.codeVerfahrensschritt,
                              stepIndex,
                              codeVerfahrensteilschritt: vts.codeVerfahrensteilschritt,
                              mindestdauer: vts.mindestdauer,
                              maxdauer: vts.maxdauer,
                              termine: vts.termine,
                              unterverfahrensteilschritte: vts.unterverfahrensteilschritte,
                              informationsID: vts.informationsID,
                            };

                            stepIndex++;
                          }
                        }
                      }

                      if (vs.codeVerfahrensschritt?.code && vs.codeVerfahrensschritt?.name) {
                        vsObj[vs.codeVerfahrensschritt.code] = {
                          name: vs.codeVerfahrensschritt.name,
                          verfahrensteilschritte:
                            Object.keys(vtsObj).length > 0 ? vtsObj : undefined,
                        };
                      }
                    }
                  }

                  proceedingStructures[proceedingtype.verfahrenssteuerung.code] = {
                    name: proceedingtype.verfahrenssteuerung.name,
                    verfahrensschritte: Object.keys(vsObj).length > 0 ? vsObj : undefined,
                  };
                }
              });

              this.proceedingStructures = proceedingStructures;

              appStore.resolved["/konfigurationen/verfahren"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage:
                  "Bereitstellung der Verfahrenskonfigurationen ist fehlgeschlagen!",
              });

              reject(error);
            });
        } else {
          resolve(false);
        }
      });
    },
    /**
     * Loads and caches user related information.
     * @returns {Promise<any>}
     */
    loadUserSettings() {
      const appStore = useAppStore();

      return new Promise((resolve) => {
        if (appStore.resolved["/nutzer"] === false) {
          openAPIFactory
            .nutzerResourceApiFactory()
            .getNutzer()
            .then((response) => {
              this.userSettings = {
                nutzerID: response.data.nutzerID,
                vollerName: response.data.vollerName,
                email: response.data.email,
                rechte: Array.from(response.data.rechte),
                rollen: Array.from(response.data.rollen),
                cockpitSettings: Object.assign(
                  {
                    customProceedingOrder: [],
                    sortingOrder: "CUSTOM",
                  },
                  JSON.parse(
                    Object.getOwnPropertyDescriptor(response.data, "cockpitSettings") !== undefined
                      ? response.data.cockpitSettings
                      : "{}",
                  ),
                ),
                zustaendigkeit: response.data.zustaendigkeit,
              };

              appStore.resolved["/nutzer"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Nutzerdaten konnten nicht geladen werden!",
              });
              appStore.hasUserLoadingError = true;
            });
        }
      });
    },
    /**
     * Loads and caches available Rechtszitate.
     * @param {Boolean} reload
     *   Ignore cache and reload rechtszitate.
     * @returns {AxiosPromise<RechtszitatDetailRest[]>}
     */
    loadRechtszitate(reload = false) {
      const appStore = useAppStore();

      if (!reload && this.rechtszitate !== undefined) {
        return new Promise((resolve) => resolve(this.rechtszitate));
      }

      return new Promise((resolve, reject) => {
        openAPIFactory
          .rechtszitatResourceApiFactory()
          .getAllRechtszitat()
          .then((response) => {
            this.rechtszitate = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Abfrage der Rechtszitate fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Deletes a specific Rechtszitat.
     * @param {String} name
     *   The name identifier of the parameter that will be deleted
     * @returns {AxiosPromise<void>}
     */
    deleteRechtszitat(name) {
      const appStore = useAppStore();

      return openAPIFactory
        .rechtszitatResourceApiFactory()
        .deleteRechtszitat(name)
        .then(() => {
          const indexToRemove = this.rechtszitate.findIndex((param) => param.name === name);

          if (indexToRemove !== -1) {
            this.rechtszitate.splice(indexToRemove, 1);
          }

          return true;
        })
        .catch((error) => {
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Beim Löschen des Rechtszitats ist ein Fehler aufgetreten!",
          });
        });
    },
    /**
     * Loads and caches available system parameters
     * (including sensitive details)
     * Note: To be used only with admin rights
     */
    loadSystemParameters() {
      return new Promise((resolve, reject) => {
        const appStore = useAppStore();

        openAPIFactory
          .systemParameterResourceApiFactory()
          .getAllSystemparameter(this.showAllSystemParameters)
          .then((response) => {
            this.systemParameters = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Abfrage der Systemparameter fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads and caches a single system parameters.
     * @param {string} name
     * @param {boolean} reload Ignore cache and reload systemparameters.
     */
    loadSystemParameter(name, reload = false) {
      let systemParameter = this.getSystemParameterByName(name);

      if (!reload && systemParameter !== undefined) {
        return new Promise((resolve) => resolve(systemParameter));
      }

      return new Promise((resolve, reject) => {
        const appStore = useAppStore();

        openAPIFactory
          .systemParameterResourceApiFactory()
          .getSystemParameter(name)
          .then((response) => {
            this.updateSystemParameter(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: `Abfrage des Systemparameters "${name}" fehlgeschlagen!`,
            });

            reject(error);
          });
      });
    },
    /**
     * Removes a specific system parameter.
     * @param {String} parameterName
     *   The parameter name.
     * @returns {void}
     */
    removeSystemParameter(parameterName) {
      const systemParameters = [...this.systemParameters];

      this.systemParameters = systemParameters.filter((param) => param.name !== parameterName);
    },
    /**
     * Loads and caches the date blacklist for datepickers.
     * @returns {void}
     */
    loadDateBlacklist() {
      const appStore = useAppStore();

      if (appStore.resolved["/konfigurationen/blacklist"] === false) {
        openAPIFactory
          .blacklistResourceApiFactory()
          .getBlacklist()
          .then((response) => {
            const dateBlacklist = response.data;

            dateBlacklist.forEach((entry) => {
              entry.from = entry.beginn;
              entry.to = entry.ende;
              entry.beginn = moment(entry.beginn).format("DD.MM.YYYY");
              entry.ende = moment(entry.ende).format("DD.MM.YYYY");
            });

            this.dateBlacklist = dateBlacklist;

            appStore.resolved["/konfigurationen/blacklist"] = true;
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Die Abfrage der Datums-Blacklist ist fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Loads and caches the date whitelist for datepickers.
     * @returns {AxiosPromise<Array<WhitelistTerminRest>>}
     */
    loadDateWhitelist() {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .whitelistResourceApiFactory()
          .getWhitelist()
          .then((response) => {
            this.whiteListData = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Loads and caches date whitelist series for datepickers.
     * @returns {AxiosPromise<Array<WhitelistTerminSerieRest>>}
     */
    loadDateWhitelistSeries() {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .whitelistResourceApiFactory()
          .getWhitelistSerie()
          .then((response) => {
            this.whiteListSeriesData = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Returns a function retrieving the district specific date whitelist.
     * @param {Object} state
     *   The state of this store module
     * @return {function}
     *   Function returning the date whitelist for a given district code or an empty array if not yet loaded.
     */
    dateWhitelistByZustaendigkeit(state) {
      return (zustaendigkeit) => {
        return Array.isArray(state.dateWhitelist[zustaendigkeit])
          ? state.dateWhitelist[zustaendigkeit]
          : [];
      };
    },
    /**
     * Loads and caches the city district specific date whitelist.
     * @param {String} zustaendigkeit
     *   The code of the city district to load the whitelist for.
     * @returns {void}
     */
    loadDateWhitelistByZustaendigkeit(zustaendigkeit) {
      const appStore = useAppStore();

      if (
        this.dateWhitelist[zustaendigkeit] === undefined ||
        (this.dateWhitelist[zustaendigkeit] !== "loading" &&
          this.dateWhitelist[zustaendigkeit].length < 1)
      ) {
        this.dateWhitelist[zustaendigkeit] = "loading";

        openAPIFactory
          .whitelistResourceApiFactory()
          .getWhitelistByZustaendigkeit(zustaendigkeit)
          .then((response) => {
            this.dateWhitelist[zustaendigkeit] = [...response.data];
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage:
                "Die Abfrage der Datums-Whitelist für den Bezirk " +
                zustaendigkeit +
                "ist fehlgeschlagen!",
            });
          });
      }
    },
    /**
     * Deletes a specific whitelist.
     * @param {Number} whitelistID
     * @returns  {AxiosPromise<void>}
     */
    deleteWhiteListById(whitelistID) {
      const appStore = useAppStore();

      return openAPIFactory
        .whitelistResourceApiFactory()
        .deleteWhitelist([whitelistID])
        .catch((error) => {
          appStore.showErrorModal({
            response: error,
            customErrorMessage: "Beim Löschen des Sitzungstermins ist ein Fehler aufgetreten!",
          });
        });
    },
    /**
     * Updates or creates a specific system parameter.
     * @param {Object} systemParameterData
     *   The parameter object to update.
     * @returns {void}
     */
    updateSystemParameter(systemParameterData) {
      const systemParameters = [...this.systemParameters];
      const parameterIndex = systemParameters.findIndex(
        (param) => param.name === systemParameterData.name,
      );

      // This mutation is used both for editing existing parameters as well as
      // inserting new ones. We need to distinct between these cases in order
      // to perform to correct action.
      if (parameterIndex > -1) {
        systemParameters.splice(parameterIndex, 1, systemParameterData);
      } else {
        systemParameters.push(systemParameterData);
      }

      this.systemParameters = systemParameters;
    },
  },
  getters: {
    /**
     * Returns the XBau codelist defined by its name.
     * @return {function(name: String): Array}
     *   An array of codelist items.
     */
    getCodelistByName() {
      return (name) => {
        return this.codelists && this.codelists[name.toLowerCase()]
          ? this.codelists[name.toLowerCase()]
          : [];
      };
    },
    /**
     * Returns a specific system parameter.
     * @return {function(name: String): (String|undefined)}
     *   The value of the system parameter.
     */
    getSystemParameterByName() {
      return (name) => {
        return this.systemParameters
          ? this.systemParameters.find(
              (parameter) => parameter.name.toLowerCase() === name.toLowerCase(),
            )
          : undefined;
      };
    },
    /**
     * Checks if  "zustaendigkeit" is defined and is not "Standard"
     * ("zustaendigkeit" defines restricted access for proceedings)
     * @param state
     * @returns {boolean}
     */
    isUserWithZustaendigkeit(state) {
      return !!(
        state.userSettings?.zustaendigkeit?.name &&
        state.userSettings.zustaendigkeit.name !== "Standard"
      );
    },
    /**
     * Check if a value exists in a codelist
     * @return {function(value: Object, codelistName: String): Boolean}
     * */
    checkValueInCodelist() {
      return (value, codelistName) => {
        const codelist = this.getCodelistByName(codelistName);

        return !!codelist.find(
          (codelistValue) => codelistValue.name === value.name && codelistValue.code === value.code,
        );
      };
    },
    /**
     * Returns a boolean indicating if a user has a given permission.
     * @return {function(permissionID: String): Boolean}
     *   The result of the check.
     */
    checkUserPermission() {
      return (permissionID) => {
        return this.userSettings
          ? this.userSettings.rechte
              .map((permissionID) => permissionID.toLowerCase())
              .indexOf(permissionID.toLowerCase()) > -1
          : false;
      };
    },
    /**
     * Returns a datumsstatus object ordered by name
     * @returns {{String: String}}
     *   Object containing code and name of datumsstatus code list
     */
    datumsStatusByName() {
      const datumsstatus = this.getCodelistByName("datumsstatus");
      let datumsStatusObj = {};

      datumsstatus.forEach((status) => {
        datumsStatusObj[status.name] = status.code;
      });

      return datumsStatusObj;
    },
    /**
     * Returns an array of Verfahrenssteuerung types including the existing verfahrenssteuerung from 'proceedingStructures'
     * @returns {Array}
     */
    verfahrenssteuerungTypes() {
      return Object.keys(this.proceedingStructures).reduce((accumulated, currentKey) => {
        return [
          ...accumulated,
          {
            code: currentKey,
            name: this.proceedingStructures[currentKey].name,
          },
        ];
      }, []);
    },
    /**
     * Returns an array of Verfahrensteilschritte based on verfahrenssteuerungsTyp and verfahrensschritt passed.
     * @return {function(*, *): [...*[],{code: string, name}]}
     */
    getVerfahrensteilschritte() {
      return (verfahrenssteuerungsTyp, verfahrensschritt) => {
        const subStepDefinitions =
          this.proceedingStructures[verfahrenssteuerungsTyp.code].verfahrensschritte[
            verfahrensschritt.code
          ].verfahrensteilschritte;

        return Object.keys(subStepDefinitions).reduce((accumulated, currentKey) => {
          return [
            ...accumulated,
            {
              code: currentKey,
              name: subStepDefinitions[currentKey].name,
            },
          ];
        }, []);
      };
    },
  },
});
