import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.js";
import { useFundamentalStore } from "@/stores/fundamental.js";
import { useProceedingsStore } from "@/stores/proceedings.js";

/**
 * updates a user proceeding
 * @param {Array<NutzerVerfahrenRest>} userSubscriptionIds
 * @returns { AxiosPromise<NutzerVerfahrenRest[]>}
 */
export function updateUserProceedings(userSubscriptionIds) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .nutzerResourceApiFactory()
      .updateNutzerVerfahren(userSubscriptionIds)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * update user settings
 * @param {Object} cockpitSettings
 */
export function updateUserSettings(cockpitSettings) {
  const cockpitSettingsRequestBody = JSON.stringify(cockpitSettings);

  return new Promise((resolve, reject) => {
    openAPIFactory
      .nutzerResourceApiFactory()
      .updateNutzerSettings({ cockpitSettings: cockpitSettingsRequestBody })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Returns the proceeding configuration object.
 * @param {string} proceedingID
 * @returns {Promise<VerfahrensKonfigurationRest>}
 */
export function getProceedingConfiguration(proceedingID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .verfahrenResourceApiFactory()
      .getVerfahrenskonfiguration(proceedingID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Loads the settings for metadaten export
 * @param {string} planID
 */
export function loadVerfahrenEinstellungenByID(planID) {
  const appStore = useAppStore();

  return new Promise((resolve, reject) => {
    appStore.showPageLoadingIndicator({
      id: "loadVerfahrenEinstellungen",
      text: "Moment. Die Einstellungen werden geladen.",
    });
    openAPIFactory
      .VerfahrenEinstellungenRessourceApiFactory()
      .getEinstellungen(planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        appStore.showErrorModal({
          response: error,
          customErrorMessage: "Laden der CSW-ID fehlgeschlagen!",
        });
        reject(error);
      })
      .finally(() => {
        appStore.hidePageLoadingIndicator("loadVerfahrenEinstellungen");
      });
  });
}

/**
 * Sets the settings for metadaten export
 * @param {string} planID
 * @param {VerfahrenEinstellungenRest} verfahrenEinstellungen - Contains the metadatenID and beteiligungsID
 */
export function setVerfahrenEinstellungenByID(planID, verfahrenEinstellungen) {
  const appStore = useAppStore();

  return new Promise((resolve, reject) => {
    appStore.showPageLoadingIndicator({
      id: "setVerfahrenEinstellungen",
      text: "Moment. Die Einstellungen werden gespeichert.",
    });
    openAPIFactory
      .VerfahrenEinstellungenRessourceApiFactory()
      .setEinstellungen(verfahrenEinstellungen, planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        appStore.showErrorModal({
          response: error,
          customErrorMessage: "Speichern der CSW-ID fehlgeschlagen!",
        });
        reject(error);
      })
      .finally(() => {
        appStore.hidePageLoadingIndicator("setVerfahrenEinstellungen");
      });
  });
}

/**
 * updates a Planname
 * @param {string} planID
 * @param {VerfahrenEditRest} proceedingData - data with new planname
 * @returns {AxiosPromise<VerfahrenDetailRest>}
 */
export function updatePlanname(planID, proceedingData) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .verfahrenResourceApiFactory()
      .updateVerfahrenPlanname(proceedingData, planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Method to update subscribed proceedings based on action
 * @param {string} subscriptionPlanID planID of proceeding to add/remove
 * @param {string} action "add" or "remove"
 */
export async function updateSubscribedProceedings(subscriptionPlanID, action) {
  const appStore = useAppStore();
  const proceedingsStore = useProceedingsStore();

  try {
    const subscribedProceedingIDs = proceedingsStore.subscribedProceedingIDs;
    const resultingSubscriptionList =
      action === "add"
        ? [...subscribedProceedingIDs, subscriptionPlanID]
        : subscribedProceedingIDs.filter((planID) => planID !== subscriptionPlanID);

    const userSubscriptionIds = resultingSubscriptionList.map((planID) => ({ planID }));

    const response = await updateUserProceedings(userSubscriptionIds);

    if (response) {
      proceedingsStore.subscribedProceedingIDs = resultingSubscriptionList;

      if (action === "add") {
        // reload proceeding after NutzerVerfahren was updated
        await proceedingsStore.loadProceedingByID(subscriptionPlanID, true);
        proceedingsStore.setProceedingIsInitialLoad(subscriptionPlanID, true);
      }

      proceedingsStore.alterSubscriptions({
        subscribedProceedingIDs: resultingSubscriptionList,
        action: action,
        proceeding:
          action === "add"
            ? proceedingsStore.getProceedingWorkingCopy(subscriptionPlanID)
            : undefined,
        proceedingID: action === "add" ? undefined : subscriptionPlanID,
      });
    }
  } catch (error) {
    await appStore.showErrorModal({
      response: error,
      customErrorMessage: "Aktualisierung der abonnierten Verfahren fehlgeschlagen!",
    });
  }
}

// TODO move to fundamentalStore
/**
 * Method to update stored subscribed proceedings order
 * @param {Array<VerfahrenUebersichtRest>} orderedProceedingList  list of new ordered subscribed proceedings
 * @returns {void}
 */
export function reorderProceedings(orderedProceedingList = []) {
  const fundamentalStore = useFundamentalStore();
  const proceedingOrder = orderedProceedingList.map((item) => item?.planID);

  const cockpitSettings = {
    customProceedingOrder: proceedingOrder,
    sortingOrder: "CUSTOM",
  };

  updateUserSettings(cockpitSettings).then(() => {
    fundamentalStore.userSettings.cockpitSettings = cockpitSettings;
  });
}
