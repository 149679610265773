import moment from "moment";

import { authInterceptor, authRequestInterceptor, axiosInstance } from "@/services/axios.js";
import { openAPIFactory } from "@/services/open-api.js";

/**
 * get the binary of a systemparameter
 * @param {string} paramName
 * @returns {Promise<AxiosResponse<string[]>>}
 */
export async function getSystemParameterBinary(paramName) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .systemParameterResourceApiFactory()
      .getSystemparameterBinary(paramName, { responseType: "blob" })
      .then((response) => {
        resolve(response); // TODO use .data
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Gets details of transfer messages based on transferMessageID passed.
 * @param {object} payload
 * @param {number} payload.transferMessageID
 * @param {any} [payload.options] Override http request option.
 * @returns {Promise<TransferMessageDetailRest>}
 */
export async function loadTransferMessageDetails({ transferMessageID, options }) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .transferMessageResourceApiFactory()
      .getTransferMessageDetail(transferMessageID, options)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Loads all the Protocol messages
 * @param {object} payload
 * @param {object} payload.filterParams filtering params : typ, status, beschreibung, bearbeitet von, datum
 * @param {number} [payload.page] Zero-based page index (0..N)
 * @param {number} [payload.size] The size of the page to be returned
 * @param {string[]} [payload.sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 * @param {any} [payload.options] Override http request option.
 * @returns {Promise<PageProtokollRest>}
 */
export async function loadProtocols({ filterParams, page, size, sort, options }) {
  const status = filterParams.Status?.length ? filterParams.Status : undefined;
  const typ = filterParams.Typ?.length ? filterParams.Typ : undefined;
  const beschreibung = filterParams.Beschreibung?.[0];
  const erfassungsdatum = filterParams.Datum?.length
    ? moment(filterParams.Datum[0], "DD.MM.YYYY").format("YYYY-MM-DD")
    : undefined;
  const erfasser = filterParams["Bearbeitet von"]?.[0];

  return new Promise((resolve, reject) => {
    openAPIFactory
      .protokollResourceApiFactory()
      .getProtokolle(
        status,
        typ,
        beschreibung,
        erfasser,
        erfassungsdatum,
        page,
        size,
        sort,
        options,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get the value of a systemparameter
 * @param {string} paramName
 * @returns {Promise<AxiosResponse<SystemParameterRest>>}
 */
export async function getSystemParameterWithoutAuthentication(paramName) {
  // no authentication needed for this request
  axiosInstance.interceptors.request.eject(authRequestInterceptor);

  return new Promise((resolve, reject) => {
    openAPIFactory
      .systemParameterResourceApiFactory()
      .getSystemParameterWithoutAuthentication(paramName)
      .then((response) => {
        resolve(response); // TODO use .data
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        // enable authentication
        axiosInstance.interceptors.request.use(authInterceptor);
      });
  });
}

/**
 * Get all the generic codelist values
 * @returns {Promise< AxiosResponse<GenericCodelistUebersichtRest[]>>}
 */
export async function getGenericCodeLists() {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .genericCodelistResourceApiFactory()
      .getAllGenericCodelists()
      .then((response) => {
        resolve(response); // TODO use .data
      })
      .catch((error) => {
        reject(error);
      });
  });
}
