/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.140.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CodeBezirke } from '../models';
// @ts-ignore
import type { CodeGebaeudeart } from '../models';
// @ts-ignore
import type { CodeGebietseinheit } from '../models';
// @ts-ignore
import type { CodePlanart } from '../models';
// @ts-ignore
import type { CodePlanstatus } from '../models';
// @ts-ignore
import type { CodeVerfahrensstand } from '../models';
// @ts-ignore
import type { CodeVerfahrenssteuerung } from '../models';
// @ts-ignore
import type { CodeWettbewerbsart } from '../models';
// @ts-ignore
import type { CodeZustaendigkeit } from '../models';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { PageProtokollRest } from '../models';
// @ts-ignore
import type { ParallelVerfahrenEditRest } from '../models';
// @ts-ignore
import type { VerfahrenBebauungsplaeneRest } from '../models';
// @ts-ignore
import type { VerfahrenDetailRest } from '../models';
// @ts-ignore
import type { VerfahrenEditRest } from '../models';
// @ts-ignore
import type { VerfahrenEinfacheSucheRest } from '../models';
// @ts-ignore
import type { VerfahrenNeuRest } from '../models';
// @ts-ignore
import type { VerfahrenPatchResultRest } from '../models';
// @ts-ignore
import type { VerfahrenUebersichtRest } from '../models';
// @ts-ignore
import type { VerfahrenUmringeRest } from '../models';
// @ts-ignore
import type { VerfahrensKonfigurationRest } from '../models';
/**
 * VerfahrenResourceApi - axios parameter creator
 * @export
 */
export const VerfahrenResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> <b>Beschreibung:</b> Erzeugt ein neues Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN
         * @summary Erzeugt ein neues Verfahren.
         * @param {VerfahrenNeuRest} verfahren 
         * @param {File} [xplangml] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createVerfahren1: async (verfahren: VerfahrenNeuRest, xplangml?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahren' is not null or undefined
            assertParamExists('createVerfahren1', 'verfahren', verfahren)
            const localVarPath = `/verfahren/neu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (verfahren !== undefined) { 
                localVarFormParams.append('verfahren', new Blob([JSON.stringify(verfahren)], { type: "application/json", }));
            }
    
            if (xplangml !== undefined) { 
                localVarFormParams.append('xplangml', xplangml as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Löscht ein Verfahren (wenn simuliert, sonst nur markiert).</br></br><b>Benötigte Rechte:</b> VERFAHREN_DELETE or EIGENE_VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or ADMIN
         * @summary Löscht ein Verfahren (wenn simuliert, sonst nur markiert).
         * @param {string} planID planID des Verfahrens welches gelöscht werden soll
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVerfahren: async (planID: string, force?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteVerfahren', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/loeschen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Einfache Suche nach {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Einfache Suche nach {@link Verfahren Verfahren}.
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [ehemaligerPlanname] 
         * @param {string} [bueroId] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
         * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        einfacheSucheVerfahren: async (name?: string, plannameArbeitstitel?: string, ehemaligerPlanname?: string, bueroId?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verfahren/suche/einfach`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (plannameArbeitstitel !== undefined) {
                localVarQueryParameter['plannameArbeitstitel'] = plannameArbeitstitel;
            }

            if (ehemaligerPlanname !== undefined) {
                localVarQueryParameter['ehemaligerPlanname'] = ehemaligerPlanname;
            }

            if (bueroId !== undefined) {
                localVarQueryParameter['bueroId'] = bueroId;
            }

            if (besitzer !== undefined) {
                localVarQueryParameter['besitzer'] = besitzer;
            }

            if (codePlanstatus) {
                localVarQueryParameter['codePlanstatus'] = codePlanstatus;
            }

            if (codeZustaendigkeit) {
                localVarQueryParameter['codeZustaendigkeit'] = codeZustaendigkeit;
            }

            if (codeBezirk) {
                localVarQueryParameter['codeBezirk'] = codeBezirk;
            }

            if (codeGebietseinheit) {
                localVarQueryParameter['codeGebietseinheit'] = codeGebietseinheit;
            }

            if (codePlanart) {
                localVarQueryParameter['codePlanart'] = codePlanart;
            }

            if (codeVerfahrenssteuerung) {
                localVarQueryParameter['codeVerfahrenssteuerung'] = codeVerfahrenssteuerung;
            }

            if (codeVerfahrensstand) {
                localVarQueryParameter['codeVerfahrensstand'] = codeVerfahrensstand;
            }

            if (codeGebaeudeart) {
                localVarQueryParameter['codeGebaeudeart'] = codeGebaeudeart;
            }

            if (codeWettbewerbsart) {
                localVarQueryParameter['codeWettbewerbsart'] = codeWettbewerbsart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] 
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenstypen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVerfahren: async (name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (plannameArbeitstitel !== undefined) {
                localVarQueryParameter['plannameArbeitstitel'] = plannameArbeitstitel;
            }

            if (besitzer !== undefined) {
                localVarQueryParameter['besitzer'] = besitzer;
            }

            if (codePlanstatus) {
                localVarQueryParameter['codePlanstatus'] = codePlanstatus;
            }

            if (codeZustaendigkeit) {
                localVarQueryParameter['codeZustaendigkeit'] = codeZustaendigkeit;
            }

            if (codeBezirk) {
                localVarQueryParameter['codeBezirk'] = codeBezirk;
            }

            if (codeGebietseinheit) {
                localVarQueryParameter['codeGebietseinheit'] = codeGebietseinheit;
            }

            if (codePlanart) {
                localVarQueryParameter['codePlanart'] = codePlanart;
            }

            if (codeVerfahrenssteuerung) {
                localVarQueryParameter['codeVerfahrenssteuerung'] = codeVerfahrenssteuerung;
            }

            if (codeVerfahrensstand) {
                localVarQueryParameter['codeVerfahrensstand'] = codeVerfahrensstand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.
         * @param {Array<string>} codePlanstatus Verfahrenstypen, default Bezirksverfahren, Senatsverfahren
         * @param {Array<string>} codeVerfahrensstand Verfahrensstand, default alle ausser simulierte Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAngefochteneBebauungsplaene: async (codePlanstatus: Array<string>, codeVerfahrensstand: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'codePlanstatus' is not null or undefined
            assertParamExists('getAngefochteneBebauungsplaene', 'codePlanstatus', codePlanstatus)
            // verify required parameter 'codeVerfahrensstand' is not null or undefined
            assertParamExists('getAngefochteneBebauungsplaene', 'codeVerfahrensstand', codeVerfahrensstand)
            const localVarPath = `/verfahren/angefochtene-bebauungsplaene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codePlanstatus) {
                localVarQueryParameter['codePlanstatus'] = codePlanstatus;
            }

            if (codeVerfahrensstand) {
                localVarQueryParameter['codeVerfahrensstand'] = codeVerfahrensstand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.
         * @param {Array<string>} [codeVerfahrenssteuerung] Verfahrenssteuerungen, default: Bezirksverfahren, Senatsverfahren
         * @param {Array<string>} [codeVerfahrensstand] Verfahrensstand, default: alle ausser simulierte Verfahren
         * @param {Array<CodePlanart>} [codePlanart] Planart, default: alle Planarten
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUmringe: async (codeVerfahrenssteuerung?: Array<string>, codeVerfahrensstand?: Array<string>, codePlanart?: Array<CodePlanart>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verfahren/export/umringe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codeVerfahrenssteuerung) {
                localVarQueryParameter['codeVerfahrenssteuerung'] = codeVerfahrenssteuerung;
            }

            if (codeVerfahrensstand) {
                localVarQueryParameter['codeVerfahrensstand'] = codeVerfahrensstand;
            }

            if (codePlanart) {
                localVarQueryParameter['codePlanart'] = codePlanart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenDetail: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrenDetail', 'planID', planID)
            const localVarPath = `/verfahren/{planID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {string} karteID 
         * @param {boolean} [mitLegende] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenKarte: async (planID: string, karteID: string, mitLegende?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrenKarte', 'planID', planID)
            // verify required parameter 'karteID' is not null or undefined
            assertParamExists('getVerfahrenKarte', 'karteID', karteID)
            const localVarPath = `/verfahren/{planID}/karten/{karteID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"karteID"}}`, encodeURIComponent(String(karteID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mitLegende !== undefined) {
                localVarQueryParameter['mitLegende'] = mitLegende;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {Array<GetVerfahrenProtokolleStatus>} [status] 
         * @param {Array<GetVerfahrenProtokolleTyp>} [typ] 
         * @param {string} [beschreibung] 
         * @param {string} [erfasser] 
         * @param {string} [erfassungsdatum] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenProtokolle: async (planID: string, status?: Array<GetVerfahrenProtokolleStatus>, typ?: Array<GetVerfahrenProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrenProtokolle', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/protokolle`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (typ) {
                localVarQueryParameter['typ'] = typ;
            }

            if (beschreibung !== undefined) {
                localVarQueryParameter['beschreibung'] = beschreibung;
            }

            if (erfasser !== undefined) {
                localVarQueryParameter['erfasser'] = erfasser;
            }

            if (erfassungsdatum !== undefined) {
                localVarQueryParameter['erfassungsdatum'] = (erfassungsdatum as any instanceof Date) ?
                    (erfassungsdatum as any).toISOString() :
                    erfassungsdatum;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenskonfiguration: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getVerfahrenskonfiguration', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/konfiguration`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Ermittelt das Planwerk Archiv.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @summary Ermittelt das Planwerk Archiv.
         * @param {string} planID die {@link Verfahren Verfahren} planID (nicht zu verwechseln mit dem in der XPlanBox)
         * @param {string} xPlanBoxID die ID in der XPlanBox (z.B. xPlanMgrID am Verfahren)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXPlanManagerRessource: async (planID: string, xPlanBoxID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getXPlanManagerRessource', 'planID', planID)
            // verify required parameter 'xPlanBoxID' is not null or undefined
            assertParamExists('getXPlanManagerRessource', 'xPlanBoxID', xPlanBoxID)
            const localVarPath = `/verfahren/{planID}/planwerk/{xPlanBoxID}/planarchiv`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"xPlanBoxID"}}`, encodeURIComponent(String(xPlanBoxID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ermittelt das Planwerk Archiv. Dies ist ein öffentlicher Endpunkt (keine Authentifizierung, Authorisierung),  siehe <a href=\"https://www.dev.diplanung.de/DefaultCollection/EfA%20DiPlanung/_workitems/edit/14838\">ADO-14838</a>.   <p>      Wenn die ID des Planwerks in der XPlanBox Id bekannt ist, sollte besser der Aufruf damit erfolgen,      da er die fehleranfällige Suche über den Plannamen vermeidet.  </p>
         * @summary Ermittelt das Planwerk Archiv
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXPlanManagerRessource1: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getXPlanManagerRessource1', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/planarchiv`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Startet ein Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Startet ein Verfahren.
         * @param {VerfahrenEditRest} verfahrenEditRest Daten des Verfahrens
         * @param {string} planID planID des Verfahrens welches gestartet werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVerfahren: async (verfahrenEditRest: VerfahrenEditRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenEditRest' is not null or undefined
            assertParamExists('startVerfahren', 'verfahrenEditRest', verfahrenEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('startVerfahren', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/start`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {string} [ehemaligerPlanname] 
         * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
         * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
         * @param {string} [bueroId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sucheVerfahren: async (name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, ehemaligerPlanname?: string, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, bueroId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verfahren/suche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (plannameArbeitstitel !== undefined) {
                localVarQueryParameter['plannameArbeitstitel'] = plannameArbeitstitel;
            }

            if (besitzer !== undefined) {
                localVarQueryParameter['besitzer'] = besitzer;
            }

            if (codePlanstatus) {
                localVarQueryParameter['codePlanstatus'] = codePlanstatus;
            }

            if (codeZustaendigkeit) {
                localVarQueryParameter['codeZustaendigkeit'] = codeZustaendigkeit;
            }

            if (codeBezirk) {
                localVarQueryParameter['codeBezirk'] = codeBezirk;
            }

            if (codeGebietseinheit) {
                localVarQueryParameter['codeGebietseinheit'] = codeGebietseinheit;
            }

            if (codePlanart) {
                localVarQueryParameter['codePlanart'] = codePlanart;
            }

            if (codeVerfahrenssteuerung) {
                localVarQueryParameter['codeVerfahrenssteuerung'] = codeVerfahrenssteuerung;
            }

            if (codeVerfahrensstand) {
                localVarQueryParameter['codeVerfahrensstand'] = codeVerfahrensstand;
            }

            if (ehemaligerPlanname !== undefined) {
                localVarQueryParameter['ehemaligerPlanname'] = ehemaligerPlanname;
            }

            if (codeGebaeudeart) {
                localVarQueryParameter['codeGebaeudeart'] = codeGebaeudeart;
            }

            if (codeWettbewerbsart) {
                localVarQueryParameter['codeWettbewerbsart'] = codeWettbewerbsart;
            }

            if (bueroId !== undefined) {
                localVarQueryParameter['bueroId'] = bueroId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert die Parallelverfahren eines Verfahren</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert die Parallelverfahren eines Verfahren
         * @param {ParallelVerfahrenEditRest} parallelVerfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParallelVerfahren: async (parallelVerfahrenEditRest: ParallelVerfahrenEditRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parallelVerfahrenEditRest' is not null or undefined
            assertParamExists('updateParallelVerfahren', 'parallelVerfahrenEditRest', parallelVerfahrenEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateParallelVerfahren', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/parallelverfahren`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parallelVerfahrenEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Verfahren (voll).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert ein Verfahren (voll).
         * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahren: async (verfahrenEditRest: VerfahrenEditRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenEditRest' is not null or undefined
            assertParamExists('updateVerfahren', 'verfahrenEditRest', verfahrenEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateVerfahren', 'planID', planID)
            const localVarPath = `/verfahren/{planID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Verfahren (partial).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert ein Verfahren (partial).
         * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahrenPartial: async (verfahrenEditRest: VerfahrenEditRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenEditRest' is not null or undefined
            assertParamExists('updateVerfahrenPartial', 'verfahrenEditRest', verfahrenEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateVerfahrenPartial', 'planID', planID)
            const localVarPath = `/verfahren/{planID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Erwartet eine XML-Datei.  Beispiel:  <pre>  <code>&lt;VerfahrenPatches xmlns=&quot;http://diplanung.de/diplancockpit/V1&quot;&gt;      &lt;verfahrenPatch planID=&quot;ID_0f5c7c00-847a-451c-aa93-d5a33bf00000&quot;&gt;          &lt;planname&gt;New Planname&lt;/planname&gt;          &lt;metadatenID&gt;New MetadatenID&lt;/metadatenID&gt;          &lt;beteiligungsID&gt;New BeteiligungsID&lt;/beteiligungsID&gt;      &lt;/verfahrenPatch&gt;  &lt;/VerfahrenPatches&gt; </code>  </pre>   Elemente von verfahrenPatch sind optional. Nicht aufgeführte Elemente werden nicht überschrieben.  Elemente, die keinen Inhalt haben, werden am Verfahren auf einen leeren Inhalt geändert.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Erwartet eine XML-Datei.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahrenPartiell: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateVerfahrenPartiell', 'file', file)
            const localVarPath = `/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {VerfahrenEditRest} verfahrenEditRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahrenPlanname: async (verfahrenEditRest: VerfahrenEditRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenEditRest' is not null or undefined
            assertParamExists('updateVerfahrenPlanname', 'verfahrenEditRest', verfahrenEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateVerfahrenPlanname', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/updatePlanname`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_DELETE or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wiederherstelleVerfahren: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('wiederherstelleVerfahren', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/wiederherstellen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerfahrenResourceApi - functional programming interface
 * @export
 */
export const VerfahrenResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerfahrenResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> <b>Beschreibung:</b> Erzeugt ein neues Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN
         * @summary Erzeugt ein neues Verfahren.
         * @param {VerfahrenNeuRest} verfahren 
         * @param {File} [xplangml] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createVerfahren1(verfahren: VerfahrenNeuRest, xplangml?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVerfahren1(verfahren, xplangml, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.createVerfahren1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Löscht ein Verfahren (wenn simuliert, sonst nur markiert).</br></br><b>Benötigte Rechte:</b> VERFAHREN_DELETE or EIGENE_VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or ADMIN
         * @summary Löscht ein Verfahren (wenn simuliert, sonst nur markiert).
         * @param {string} planID planID des Verfahrens welches gelöscht werden soll
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVerfahren(planID: string, force?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVerfahren(planID, force, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.deleteVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Einfache Suche nach {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Einfache Suche nach {@link Verfahren Verfahren}.
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [ehemaligerPlanname] 
         * @param {string} [bueroId] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
         * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async einfacheSucheVerfahren(name?: string, plannameArbeitstitel?: string, ehemaligerPlanname?: string, bueroId?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenEinfacheSucheRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.einfacheSucheVerfahren(name, plannameArbeitstitel, ehemaligerPlanname, bueroId, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, codeGebaeudeart, codeWettbewerbsart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.einfacheSucheVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] 
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenstypen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVerfahren(name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenUebersichtRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVerfahren(name, plannameArbeitstitel, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getAllVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.
         * @param {Array<string>} codePlanstatus Verfahrenstypen, default Bezirksverfahren, Senatsverfahren
         * @param {Array<string>} codeVerfahrensstand Verfahrensstand, default alle ausser simulierte Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAngefochteneBebauungsplaene(codePlanstatus: Array<string>, codeVerfahrensstand: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenBebauungsplaeneRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAngefochteneBebauungsplaene(codePlanstatus, codeVerfahrensstand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getAngefochteneBebauungsplaene']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.
         * @param {Array<string>} [codeVerfahrenssteuerung] Verfahrenssteuerungen, default: Bezirksverfahren, Senatsverfahren
         * @param {Array<string>} [codeVerfahrensstand] Verfahrensstand, default: alle ausser simulierte Verfahren
         * @param {Array<CodePlanart>} [codePlanart] Planart, default: alle Planarten
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUmringe(codeVerfahrenssteuerung?: Array<string>, codeVerfahrensstand?: Array<string>, codePlanart?: Array<CodePlanart>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenUmringeRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUmringe(codeVerfahrenssteuerung, codeVerfahrensstand, codePlanart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getUmringe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrenDetail(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrenDetail(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getVerfahrenDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {string} karteID 
         * @param {boolean} [mitLegende] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrenKarte(planID: string, karteID: string, mitLegende?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrenKarte(planID, karteID, mitLegende, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getVerfahrenKarte']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {Array<GetVerfahrenProtokolleStatus>} [status] 
         * @param {Array<GetVerfahrenProtokolleTyp>} [typ] 
         * @param {string} [beschreibung] 
         * @param {string} [erfasser] 
         * @param {string} [erfassungsdatum] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrenProtokolle(planID: string, status?: Array<GetVerfahrenProtokolleStatus>, typ?: Array<GetVerfahrenProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProtokollRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrenProtokolle(planID, status, typ, beschreibung, erfasser, erfassungsdatum, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getVerfahrenProtokolle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVerfahrenskonfiguration(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrensKonfigurationRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVerfahrenskonfiguration(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getVerfahrenskonfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Ermittelt das Planwerk Archiv.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @summary Ermittelt das Planwerk Archiv.
         * @param {string} planID die {@link Verfahren Verfahren} planID (nicht zu verwechseln mit dem in der XPlanBox)
         * @param {string} xPlanBoxID die ID in der XPlanBox (z.B. xPlanMgrID am Verfahren)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXPlanManagerRessource(planID: string, xPlanBoxID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getXPlanManagerRessource(planID, xPlanBoxID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getXPlanManagerRessource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ermittelt das Planwerk Archiv. Dies ist ein öffentlicher Endpunkt (keine Authentifizierung, Authorisierung),  siehe <a href=\"https://www.dev.diplanung.de/DefaultCollection/EfA%20DiPlanung/_workitems/edit/14838\">ADO-14838</a>.   <p>      Wenn die ID des Planwerks in der XPlanBox Id bekannt ist, sollte besser der Aufruf damit erfolgen,      da er die fehleranfällige Suche über den Plannamen vermeidet.  </p>
         * @summary Ermittelt das Planwerk Archiv
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXPlanManagerRessource1(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getXPlanManagerRessource1(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.getXPlanManagerRessource1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Startet ein Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Startet ein Verfahren.
         * @param {VerfahrenEditRest} verfahrenEditRest Daten des Verfahrens
         * @param {string} planID planID des Verfahrens welches gestartet werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startVerfahren(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startVerfahren(verfahrenEditRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.startVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {string} [ehemaligerPlanname] 
         * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
         * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
         * @param {string} [bueroId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async sucheVerfahren(name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, ehemaligerPlanname?: string, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, bueroId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenUebersichtRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sucheVerfahren(name, plannameArbeitstitel, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, ehemaligerPlanname, codeGebaeudeart, codeWettbewerbsart, bueroId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.sucheVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert die Parallelverfahren eines Verfahren</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert die Parallelverfahren eines Verfahren
         * @param {ParallelVerfahrenEditRest} parallelVerfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParallelVerfahren(parallelVerfahrenEditRest: ParallelVerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParallelVerfahren(parallelVerfahrenEditRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.updateParallelVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Verfahren (voll).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert ein Verfahren (voll).
         * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVerfahren(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVerfahren(verfahrenEditRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.updateVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Verfahren (partial).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert ein Verfahren (partial).
         * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVerfahrenPartial(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVerfahrenPartial(verfahrenEditRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.updateVerfahrenPartial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Erwartet eine XML-Datei.  Beispiel:  <pre>  <code>&lt;VerfahrenPatches xmlns=&quot;http://diplanung.de/diplancockpit/V1&quot;&gt;      &lt;verfahrenPatch planID=&quot;ID_0f5c7c00-847a-451c-aa93-d5a33bf00000&quot;&gt;          &lt;planname&gt;New Planname&lt;/planname&gt;          &lt;metadatenID&gt;New MetadatenID&lt;/metadatenID&gt;          &lt;beteiligungsID&gt;New BeteiligungsID&lt;/beteiligungsID&gt;      &lt;/verfahrenPatch&gt;  &lt;/VerfahrenPatches&gt; </code>  </pre>   Elemente von verfahrenPatch sind optional. Nicht aufgeführte Elemente werden nicht überschrieben.  Elemente, die keinen Inhalt haben, werden am Verfahren auf einen leeren Inhalt geändert.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Erwartet eine XML-Datei.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVerfahrenPartiell(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenPatchResultRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVerfahrenPartiell(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.updateVerfahrenPartiell']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {VerfahrenEditRest} verfahrenEditRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVerfahrenPlanname(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVerfahrenPlanname(verfahrenEditRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.updateVerfahrenPlanname']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_DELETE or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wiederherstelleVerfahren(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wiederherstelleVerfahren(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenResourceApi.wiederherstelleVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VerfahrenResourceApi - factory interface
 * @export
 */
export const VerfahrenResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerfahrenResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> <b>Beschreibung:</b> Erzeugt ein neues Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN
         * @summary Erzeugt ein neues Verfahren.
         * @param {VerfahrenNeuRest} verfahren 
         * @param {File} [xplangml] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createVerfahren1(verfahren: VerfahrenNeuRest, xplangml?: File, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.createVerfahren1(verfahren, xplangml, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Löscht ein Verfahren (wenn simuliert, sonst nur markiert).</br></br><b>Benötigte Rechte:</b> VERFAHREN_DELETE or EIGENE_VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or ADMIN
         * @summary Löscht ein Verfahren (wenn simuliert, sonst nur markiert).
         * @param {string} planID planID des Verfahrens welches gelöscht werden soll
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVerfahren(planID: string, force?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.deleteVerfahren(planID, force, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Einfache Suche nach {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Einfache Suche nach {@link Verfahren Verfahren}.
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [ehemaligerPlanname] 
         * @param {string} [bueroId] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
         * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        einfacheSucheVerfahren(name?: string, plannameArbeitstitel?: string, ehemaligerPlanname?: string, bueroId?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrenEinfacheSucheRest>> {
            return localVarFp.einfacheSucheVerfahren(name, plannameArbeitstitel, ehemaligerPlanname, bueroId, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, codeGebaeudeart, codeWettbewerbsart, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] 
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenstypen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVerfahren(name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrenUebersichtRest>> {
            return localVarFp.getAllVerfahren(name, plannameArbeitstitel, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.
         * @param {Array<string>} codePlanstatus Verfahrenstypen, default Bezirksverfahren, Senatsverfahren
         * @param {Array<string>} codeVerfahrensstand Verfahrensstand, default alle ausser simulierte Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAngefochteneBebauungsplaene(codePlanstatus: Array<string>, codeVerfahrensstand: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrenBebauungsplaeneRest>> {
            return localVarFp.getAngefochteneBebauungsplaene(codePlanstatus, codeVerfahrensstand, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.
         * @param {Array<string>} [codeVerfahrenssteuerung] Verfahrenssteuerungen, default: Bezirksverfahren, Senatsverfahren
         * @param {Array<string>} [codeVerfahrensstand] Verfahrensstand, default: alle ausser simulierte Verfahren
         * @param {Array<CodePlanart>} [codePlanart] Planart, default: alle Planarten
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUmringe(codeVerfahrenssteuerung?: Array<string>, codeVerfahrensstand?: Array<string>, codePlanart?: Array<CodePlanart>, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrenUmringeRest>> {
            return localVarFp.getUmringe(codeVerfahrenssteuerung, codeVerfahrensstand, codePlanart, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @summary Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenDetail(planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.getVerfahrenDetail(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {string} karteID 
         * @param {boolean} [mitLegende] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenKarte(planID: string, karteID: string, mitLegende?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getVerfahrenKarte(planID, karteID, mitLegende, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {Array<GetVerfahrenProtokolleStatus>} [status] 
         * @param {Array<GetVerfahrenProtokolleTyp>} [typ] 
         * @param {string} [beschreibung] 
         * @param {string} [erfasser] 
         * @param {string} [erfassungsdatum] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenProtokolle(planID: string, status?: Array<GetVerfahrenProtokolleStatus>, typ?: Array<GetVerfahrenProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<PageProtokollRest> {
            return localVarFp.getVerfahrenProtokolle(planID, status, typ, beschreibung, erfasser, erfassungsdatum, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVerfahrenskonfiguration(planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrensKonfigurationRest> {
            return localVarFp.getVerfahrenskonfiguration(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Ermittelt das Planwerk Archiv.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @summary Ermittelt das Planwerk Archiv.
         * @param {string} planID die {@link Verfahren Verfahren} planID (nicht zu verwechseln mit dem in der XPlanBox)
         * @param {string} xPlanBoxID die ID in der XPlanBox (z.B. xPlanMgrID am Verfahren)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXPlanManagerRessource(planID: string, xPlanBoxID: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getXPlanManagerRessource(planID, xPlanBoxID, options).then((request) => request(axios, basePath));
        },
        /**
         * Ermittelt das Planwerk Archiv. Dies ist ein öffentlicher Endpunkt (keine Authentifizierung, Authorisierung),  siehe <a href=\"https://www.dev.diplanung.de/DefaultCollection/EfA%20DiPlanung/_workitems/edit/14838\">ADO-14838</a>.   <p>      Wenn die ID des Planwerks in der XPlanBox Id bekannt ist, sollte besser der Aufruf damit erfolgen,      da er die fehleranfällige Suche über den Plannamen vermeidet.  </p>
         * @summary Ermittelt das Planwerk Archiv
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXPlanManagerRessource1(planID: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getXPlanManagerRessource1(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Startet ein Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Startet ein Verfahren.
         * @param {VerfahrenEditRest} verfahrenEditRest Daten des Verfahrens
         * @param {string} planID planID des Verfahrens welches gestartet werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startVerfahren(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.startVerfahren(verfahrenEditRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} [name] name
         * @param {string} [plannameArbeitstitel] 
         * @param {string} [besitzer] besitzer
         * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
         * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
         * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
         * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
         * @param {Array<CodePlanart>} [codePlanart] codePlanarten
         * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
         * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
         * @param {string} [ehemaligerPlanname] 
         * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
         * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
         * @param {string} [bueroId] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sucheVerfahren(name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, ehemaligerPlanname?: string, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, bueroId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrenUebersichtRest>> {
            return localVarFp.sucheVerfahren(name, plannameArbeitstitel, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, ehemaligerPlanname, codeGebaeudeart, codeWettbewerbsart, bueroId, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert die Parallelverfahren eines Verfahren</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert die Parallelverfahren eines Verfahren
         * @param {ParallelVerfahrenEditRest} parallelVerfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParallelVerfahren(parallelVerfahrenEditRest: ParallelVerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.updateParallelVerfahren(parallelVerfahrenEditRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Verfahren (voll).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert ein Verfahren (voll).
         * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahren(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.updateVerfahren(verfahrenEditRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert ein Verfahren (partial).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
         * @summary Aktualisiert ein Verfahren (partial).
         * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
         * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahrenPartial(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.updateVerfahrenPartial(verfahrenEditRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Erwartet eine XML-Datei.  Beispiel:  <pre>  <code>&lt;VerfahrenPatches xmlns=&quot;http://diplanung.de/diplancockpit/V1&quot;&gt;      &lt;verfahrenPatch planID=&quot;ID_0f5c7c00-847a-451c-aa93-d5a33bf00000&quot;&gt;          &lt;planname&gt;New Planname&lt;/planname&gt;          &lt;metadatenID&gt;New MetadatenID&lt;/metadatenID&gt;          &lt;beteiligungsID&gt;New BeteiligungsID&lt;/beteiligungsID&gt;      &lt;/verfahrenPatch&gt;  &lt;/VerfahrenPatches&gt; </code>  </pre>   Elemente von verfahrenPatch sind optional. Nicht aufgeführte Elemente werden nicht überschrieben.  Elemente, die keinen Inhalt haben, werden am Verfahren auf einen leeren Inhalt geändert.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Erwartet eine XML-Datei.
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahrenPartiell(file: File, options?: RawAxiosRequestConfig): AxiosPromise<Array<VerfahrenPatchResultRest>> {
            return localVarFp.updateVerfahrenPartiell(file, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {VerfahrenEditRest} verfahrenEditRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVerfahrenPlanname(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.updateVerfahrenPlanname(verfahrenEditRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_DELETE or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wiederherstelleVerfahren(planID: string, options?: RawAxiosRequestConfig): AxiosPromise<VerfahrenDetailRest> {
            return localVarFp.wiederherstelleVerfahren(planID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerfahrenResourceApi - object-oriented interface
 * @export
 * @class VerfahrenResourceApi
 * @extends {BaseAPI}
 */
export class VerfahrenResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> <b>Beschreibung:</b> Erzeugt ein neues Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_CREATE or ADMIN
     * @summary Erzeugt ein neues Verfahren.
     * @param {VerfahrenNeuRest} verfahren 
     * @param {File} [xplangml] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public createVerfahren1(verfahren: VerfahrenNeuRest, xplangml?: File, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).createVerfahren1(verfahren, xplangml, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Löscht ein Verfahren (wenn simuliert, sonst nur markiert).</br></br><b>Benötigte Rechte:</b> VERFAHREN_DELETE or EIGENE_VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or VERFAHREN_DELETE_SIMULIERTES_VERFAHREN or ADMIN
     * @summary Löscht ein Verfahren (wenn simuliert, sonst nur markiert).
     * @param {string} planID planID des Verfahrens welches gelöscht werden soll
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public deleteVerfahren(planID: string, force?: boolean, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).deleteVerfahren(planID, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Einfache Suche nach {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @summary Einfache Suche nach {@link Verfahren Verfahren}.
     * @param {string} [name] name
     * @param {string} [plannameArbeitstitel] 
     * @param {string} [ehemaligerPlanname] 
     * @param {string} [bueroId] 
     * @param {string} [besitzer] besitzer
     * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
     * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
     * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
     * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
     * @param {Array<CodePlanart>} [codePlanart] codePlanarten
     * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
     * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
     * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
     * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public einfacheSucheVerfahren(name?: string, plannameArbeitstitel?: string, ehemaligerPlanname?: string, bueroId?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).einfacheSucheVerfahren(name, plannameArbeitstitel, ehemaligerPlanname, bueroId, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, codeGebaeudeart, codeWettbewerbsart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @summary Liefert die Metadaten zu allen {@link Verfahren Verfahren}, die ich sehen darf (abonniert habe).
     * @param {string} [name] name
     * @param {string} [plannameArbeitstitel] 
     * @param {string} [besitzer] besitzer
     * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
     * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
     * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
     * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] 
     * @param {Array<CodePlanart>} [codePlanart] codePlanarten
     * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenstypen
     * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getAllVerfahren(name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getAllVerfahren(name, plannameArbeitstitel, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Ruft alle Verfahren ab, deren Bebauungspläne im Rahmen der Normenkontrolle einer gerichtlichen Überprüfung unterzogen  werden.
     * @param {Array<string>} codePlanstatus Verfahrenstypen, default Bezirksverfahren, Senatsverfahren
     * @param {Array<string>} codeVerfahrensstand Verfahrensstand, default alle ausser simulierte Verfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getAngefochteneBebauungsplaene(codePlanstatus: Array<string>, codeVerfahrensstand: Array<string>, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getAngefochteneBebauungsplaene(codePlanstatus, codeVerfahrensstand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Exportiert blaue und rote Umringe für {@link Verfahren Verfahren} welche sich entweder im Verfahren befinden oder einen Termin  infoVeröffentlichung.bekanntmachungsdatum besitzen.
     * @param {Array<string>} [codeVerfahrenssteuerung] Verfahrenssteuerungen, default: Bezirksverfahren, Senatsverfahren
     * @param {Array<string>} [codeVerfahrensstand] Verfahrensstand, default: alle ausser simulierte Verfahren
     * @param {Array<CodePlanart>} [codePlanart] Planart, default: alle Planarten
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getUmringe(codeVerfahrenssteuerung?: Array<string>, codeVerfahrensstand?: Array<string>, codePlanart?: Array<CodePlanart>, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getUmringe(codeVerfahrenssteuerung, codeVerfahrensstand, codePlanart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @summary Liefert detaillierte Angaben zu dem angefragten {@link Verfahren Verfahren}.
     * @param {string} planID planID des {@link Verfahren Verfahren}s
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getVerfahrenDetail(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getVerfahrenDetail(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @param {string} planID 
     * @param {string} karteID 
     * @param {boolean} [mitLegende] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getVerfahrenKarte(planID: string, karteID: string, mitLegende?: boolean, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getVerfahrenKarte(planID, karteID, mitLegende, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @param {string} planID 
     * @param {Array<GetVerfahrenProtokolleStatus>} [status] 
     * @param {Array<GetVerfahrenProtokolleTyp>} [typ] 
     * @param {string} [beschreibung] 
     * @param {string} [erfasser] 
     * @param {string} [erfassungsdatum] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getVerfahrenProtokolle(planID: string, status?: Array<GetVerfahrenProtokolleStatus>, typ?: Array<GetVerfahrenProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getVerfahrenProtokolle(planID, status, typ, beschreibung, erfasser, erfassungsdatum, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getVerfahrenskonfiguration(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getVerfahrenskonfiguration(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Ermittelt das Planwerk Archiv.</br></br><b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
     * @summary Ermittelt das Planwerk Archiv.
     * @param {string} planID die {@link Verfahren Verfahren} planID (nicht zu verwechseln mit dem in der XPlanBox)
     * @param {string} xPlanBoxID die ID in der XPlanBox (z.B. xPlanMgrID am Verfahren)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getXPlanManagerRessource(planID: string, xPlanBoxID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getXPlanManagerRessource(planID, xPlanBoxID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ermittelt das Planwerk Archiv. Dies ist ein öffentlicher Endpunkt (keine Authentifizierung, Authorisierung),  siehe <a href=\"https://www.dev.diplanung.de/DefaultCollection/EfA%20DiPlanung/_workitems/edit/14838\">ADO-14838</a>.   <p>      Wenn die ID des Planwerks in der XPlanBox Id bekannt ist, sollte besser der Aufruf damit erfolgen,      da er die fehleranfällige Suche über den Plannamen vermeidet.  </p>
     * @summary Ermittelt das Planwerk Archiv
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public getXPlanManagerRessource1(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).getXPlanManagerRessource1(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Startet ein Verfahren.</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
     * @summary Startet ein Verfahren.
     * @param {VerfahrenEditRest} verfahrenEditRest Daten des Verfahrens
     * @param {string} planID planID des Verfahrens welches gestartet werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public startVerfahren(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).startVerfahren(verfahrenEditRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @param {string} [name] name
     * @param {string} [plannameArbeitstitel] 
     * @param {string} [besitzer] besitzer
     * @param {Array<CodePlanstatus>} [codePlanstatus] codePlanstatus
     * @param {Array<CodeZustaendigkeit>} [codeZustaendigkeit] codeZustaendigkeit
     * @param {Array<CodeBezirke>} [codeBezirk] codeBezirke
     * @param {Array<CodeGebietseinheit>} [codeGebietseinheit] codeGebietseinheiten
     * @param {Array<CodePlanart>} [codePlanart] codePlanarten
     * @param {Array<CodeVerfahrenssteuerung>} [codeVerfahrenssteuerung] codeVerfahrenssteuerungen
     * @param {Array<CodeVerfahrensstand>} [codeVerfahrensstand] codeVerfahrensstand
     * @param {string} [ehemaligerPlanname] 
     * @param {Array<CodeGebaeudeart>} [codeGebaeudeart] 
     * @param {Array<CodeWettbewerbsart>} [codeWettbewerbsart] 
     * @param {string} [bueroId] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public sucheVerfahren(name?: string, plannameArbeitstitel?: string, besitzer?: string, codePlanstatus?: Array<CodePlanstatus>, codeZustaendigkeit?: Array<CodeZustaendigkeit>, codeBezirk?: Array<CodeBezirke>, codeGebietseinheit?: Array<CodeGebietseinheit>, codePlanart?: Array<CodePlanart>, codeVerfahrenssteuerung?: Array<CodeVerfahrenssteuerung>, codeVerfahrensstand?: Array<CodeVerfahrensstand>, ehemaligerPlanname?: string, codeGebaeudeart?: Array<CodeGebaeudeart>, codeWettbewerbsart?: Array<CodeWettbewerbsart>, bueroId?: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).sucheVerfahren(name, plannameArbeitstitel, besitzer, codePlanstatus, codeZustaendigkeit, codeBezirk, codeGebietseinheit, codePlanart, codeVerfahrenssteuerung, codeVerfahrensstand, ehemaligerPlanname, codeGebaeudeart, codeWettbewerbsart, bueroId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiert die Parallelverfahren eines Verfahren</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
     * @summary Aktualisiert die Parallelverfahren eines Verfahren
     * @param {ParallelVerfahrenEditRest} parallelVerfahrenEditRest die zu ändernden Daten
     * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public updateParallelVerfahren(parallelVerfahrenEditRest: ParallelVerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).updateParallelVerfahren(parallelVerfahrenEditRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiert ein Verfahren (voll).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
     * @summary Aktualisiert ein Verfahren (voll).
     * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
     * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public updateVerfahren(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).updateVerfahren(verfahrenEditRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiert ein Verfahren (partial).</br></br><b>Benötigte Rechte:</b> VERFAHREN_WRITE or EIGENE_VERFAHREN_WRITE or ADMIN
     * @summary Aktualisiert ein Verfahren (partial).
     * @param {VerfahrenEditRest} verfahrenEditRest die zu ändernden Daten
     * @param {string} planID planID des Verfahrens welches aktualisiert werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public updateVerfahrenPartial(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).updateVerfahrenPartial(verfahrenEditRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Erwartet eine XML-Datei.  Beispiel:  <pre>  <code>&lt;VerfahrenPatches xmlns=&quot;http://diplanung.de/diplancockpit/V1&quot;&gt;      &lt;verfahrenPatch planID=&quot;ID_0f5c7c00-847a-451c-aa93-d5a33bf00000&quot;&gt;          &lt;planname&gt;New Planname&lt;/planname&gt;          &lt;metadatenID&gt;New MetadatenID&lt;/metadatenID&gt;          &lt;beteiligungsID&gt;New BeteiligungsID&lt;/beteiligungsID&gt;      &lt;/verfahrenPatch&gt;  &lt;/VerfahrenPatches&gt; </code>  </pre>   Elemente von verfahrenPatch sind optional. Nicht aufgeführte Elemente werden nicht überschrieben.  Elemente, die keinen Inhalt haben, werden am Verfahren auf einen leeren Inhalt geändert.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Erwartet eine XML-Datei.
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public updateVerfahrenPartiell(file: File, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).updateVerfahrenPartiell(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {VerfahrenEditRest} verfahrenEditRest 
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public updateVerfahrenPlanname(verfahrenEditRest: VerfahrenEditRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).updateVerfahrenPlanname(verfahrenEditRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_DELETE or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenResourceApi
     */
    public wiederherstelleVerfahren(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenResourceApiFp(this.configuration).wiederherstelleVerfahren(planID, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetVerfahrenProtokolleStatus = {
    INFO: 'INFO',
    FEHLER: 'FEHLER'
} as const;
export type GetVerfahrenProtokolleStatus = typeof GetVerfahrenProtokolleStatus[keyof typeof GetVerfahrenProtokolleStatus];
/**
 * @export
 */
export const GetVerfahrenProtokolleTyp = {
    BENACHRICHTIGUNG_FESTSTELLUNG: 'BENACHRICHTIGUNG_FESTSTELLUNG',
    BENACHRICHTIGUNG_VERFAHRENSSTANDWECHSEL: 'BENACHRICHTIGUNG_VERFAHRENSSTANDWECHSEL',
    BENACHRICHTIGUNG_VEROEFFENTLICHUNG: 'BENACHRICHTIGUNG_VEROEFFENTLICHUNG',
    BETEILIGUNG: 'BETEILIGUNG',
    BLACKLIST: 'BLACKLIST',
    CLAMAV: 'CLAMAV',
    CODELISTE: 'CODELISTE',
    CSW_T: 'CSW_T',
    DCAT_AP_PLU: 'DCAT_AP_PLU',
    HOUSEKEEPING_DOKUMENTE: 'HOUSEKEEPING_DOKUMENTE',
    HOUSEKEEPING_TRANSFERMESSAGE: 'HOUSEKEEPING_TRANSFERMESSAGE',
    TEMPLATE_LOAD: 'TEMPLATE_LOAD',
    KONTAKTE: 'KONTAKTE',
    LIQUIBASE: 'LIQUIBASE',
    MASSEN_UPDATE: 'MASSEN_UPDATE',
    MQ: 'MQ',
    OKTAGON: 'OKTAGON',
    PLANNAMENVALIDIERUNGSREGELN: 'PLANNAMENVALIDIERUNGSREGELN',
    SYSTEM_PARAMETER: 'SYSTEM_PARAMETER',
    VERFAHREN_ALLGEMEIN: 'VERFAHREN_ALLGEMEIN',
    VERFAHREN_KONFIGURATION: 'VERFAHREN_KONFIGURATION',
    VERFAHREN_LOESCHEN: 'VERFAHREN_LOESCHEN',
    VERFAHREN_WIEDERHERSTELLEN: 'VERFAHREN_WIEDERHERSTELLEN',
    VFDBIMPORT: 'VFDBIMPORT',
    VORLAGEN_VALIDIERUNG: 'VORLAGEN_VALIDIERUNG',
    XPLANVERFAHREN_IMPORT: 'XPLANVERFAHREN_IMPORT',
    X_PLAN_BOX: 'X_PLAN_BOX'
} as const;
export type GetVerfahrenProtokolleTyp = typeof GetVerfahrenProtokolleTyp[keyof typeof GetVerfahrenProtokolleTyp];
