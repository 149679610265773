import {
  GetAllCodelistenResolver,
  GetAllResolver,
  GetAllVerfahrenResolver,
  GetBlacklistResolver,
  GetNutzerVerfahrenResolver,
} from "@/router/services.js";

export const startRoutes = {
  path: "/",
  component: () => import("@/views/VStart.vue"),
  name: "StartPage",
  props: true,
  meta: {
    resolve: {
      "/verfahren": GetAllVerfahrenResolver,
      "/nutzer/verfahren": GetNutzerVerfahrenResolver,
      "/konfigurationen/codelisten": GetAllCodelistenResolver,
      "/konfigurationen/blacklist": GetBlacklistResolver,
    },
  },
  children: [
    {
      path: "/",
      component: () => import("@/views/VStartSubscribedProceedings.vue"),
      name: "VStartSubscribedProceedings",
      meta: {
        title: ["Meine Verfahren"],
        label: "Meine Verfahren",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
        bodyClass: "has-fixed-footer",
      },
    },
    {
      path: "/uebersicht",
      component: () => import("@/views/start/VStartSubscribedProceedingsTimelines.vue"),
      name: "VStartSubscribedProceedingsTimelines",
      meta: {
        title: ["Meine Verfahren - Verfahrensschritte"],
        label: "Meine Verfahren - Verfahrensschritte",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "/suche",
      component: () => import("@/views/VStartSearchProceedings.vue"),
      meta: {
        title: ["Verfahrenssuche"],
        label: "Verfahrenssuche",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/konfigurationen/verfahren": GetAllResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
      },
    },
    {
      path: "/neu",
      component: () => import("@/views/VStartCreateProceeding.vue"),
      meta: {
        title: ["Verfahren anlegen"],
        label: "Verfahren anlegen",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
        },
        bodyClass: "has-fixed-footer",
      },
    },
  ],
};
