/**
 * @typedef datumsStatus
 * @type {object}
 * @property {string} stattgefunden - code for stattgefunden.
 * @property {string} geplant - code for geplant.
 * @property {string} berechnet - code for berechnet.
 * @property {string} "initial prognostiziert" - code for "initial prognostiziert".
 */

import moment from "moment";

import { openAPIFactory } from "@/services/open-api.js";
import { useFundamentalStore } from "@/stores/fundamental.js";

export const emptyCustomAppointment = {
  beschreibung: null,
  codeVerfahrensschritt: null,
  codeVerfahrensteilschritt: null,
  datumstyp: null,
  geplanterOderBerechneterZeitraum: null,
  initialPrognostizierterZeitraum: null,
  infoAuftaktbeschluss: null,
  infoAufstellungsbeschluss: null,
  infoExterneAbstimmung: null,
  infoFeststellung: null,
  infoFoeb: null,
  infoFraktionssprecherbefassung: null,
  infoKfs: null,
  infoLandesplanerischeStellungnahme: null,
  infoOeffentlicheAuslegung: null,
  infoSchlussphase: null,
  infoSenatssitzung: null,
  infoStaatsarchiv: null,
  infoToeb: null,
  infoVeroeffentlichung: null,
  infoVerschickungToeb: null,
  klassifizierung: "MANUELLER_TERMIN",
  kommentar: null,
  modelldauer: null,
  modellverzoegerung: null,
  monitoringrelevant: false,
  prognoserelevant: null,
  sitzung: {
    sitzungscode: null,
    politischesErgebnis: null,
    verwaltungstechnischesErgebnis: null,
    rechtspruefungErgebnis: null,
    ergebnisBemerkung: null,
    versanddatum: null,
  },
  stattgefundenerZeitraum: null,
  terminID: null,
  zeitplanungsrelevant: true,
  datumsstatus: null,
  verzoegert: false,
  verzoegerungsgrund: null,
};

/**
 * Determine the appointment result based on appointment data.
 * @param {object} appointment - The appointment data.
 * @returns {object | null} - The appointment result or null.
 */
export function getAppointmentResult(appointment) {
  if (appointment?.sitzung !== null) {
    if (appointment.sitzung.politischesErgebnis !== null) {
      return appointment.sitzung.politischesErgebnis;
    } else if (appointment.sitzung.rechtspruefungErgebnis !== null) {
      return appointment.sitzung.rechtspruefungErgebnis;
    } else if (appointment.sitzung.verwaltungstechnischesErgebnis !== null) {
      return appointment.sitzung.verwaltungstechnischesErgebnis;
    }
  }

  return null;
}

/**
 * Returns proper termin or undefined according to its datumsstatus
 * @param {object} appointment Termin
 * @param {object} datumsStatus
 * @returns {object | undefined} relevant termin
 */
export function getProperAppointment(appointment, datumsStatus) {
  if (appointment === undefined || datumsStatus === undefined) {
    return undefined;
  }

  const type = getAppointmentType(appointment, datumsStatus);
  let selectedDate;

  switch (type) {
    case datumsStatus.stattgefunden:
      selectedDate = appointment.stattgefundenerZeitraum;
      break;
    case datumsStatus["initial prognostiziert"]:
    case datumsStatus.berechnet:
    case datumsStatus.geplant:
      selectedDate = appointment.geplanterOderBerechneterZeitraum;
      break;
    case undefined:
      selectedDate = appointment.geplanterOderBerechneterZeitraum
        ? appointment.geplanterOderBerechneterZeitraum
        : {};
  }

  return selectedDate;
}

/**
 * Retrieves shipment date information from an appointment.
 * @param {object} appointment - The appointment object containing shipment date information.
 * @returns {object} An object containing shipment date details.
 */
export function getShipmentDate(appointment) {
  return {
    name: "Versanddatum",
    date: appointment?.sitzung?.versanddatum
      ? formatTaskDate(appointment.sitzung.versanddatum)
      : "",
    status: undefined,
    statusLabel: "-",
    result: "-",
  };
}

/**
 * Format a date string into a specific date format (DD.MM.YYYY).
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} - The formatted date in the format DD.MM.YYYY.
 */
export function formatTaskDate(dateString) {
  return moment(dateString).format("DD.MM.YYYY");
}

/**
 * Return appointment status label
 * @param termin {TerminDetailRest|object}
 * @param overwriteVerzoegert {boolean} - overwrite "verzoegert" flag of current appointment for a temporary status
 * @return {string} - returns status label e.g. 'geplant' or 'verzögert' or '-'
 * */
export function getAppointmentStatusLabel(termin, overwriteVerzoegert = false) {
  const { datumsstatus, verzoegert } = termin;

  if (overwriteVerzoegert || verzoegert) {
    return "verzögert";
  }

  return datumsstatus ? datumsstatus.name : "-";
}

/**
 * Return appointment status
 * @param termin {TerminDetailRest|object}
 * @param overwriteVerzoegert {boolean} - overwrite "verzoegert" flag of current appointment for a temporary status
 * @returns {string|undefined} - returns status e.g. 'success' or undefined
 */
export function getAppointmentStatus(termin, overwriteVerzoegert = false) {
  const { datumsstatus, verzoegert } = termin;

  switch (true) {
    case overwriteVerzoegert || verzoegert:
      return "warning";
    case datumsstatus?.code === "1000":
      return "success";
    case datumsstatus?.code === "1100":
      return "neutral";
    case datumsstatus?.code === "1200":
    case datumsstatus?.code === "1300":
    case datumsstatus?.code === "1400":
      return "inactive";
    default:
      return undefined;
  }
}
/**
 * Returns an object for one date
 * @param {object} appointment appointment
 * @param {object} datumsStatus
 * @returns {object} line appointment
 */
export function getAppointmentDate(appointment, datumsStatus) {
  const properAppointment = getProperAppointment(appointment, datumsStatus);
  const appointmentResult = getAppointmentResult(appointment);
  let formattedDate;

  if (appointment.datumstyp?.code === "0200") {
    formattedDate = [properAppointment.beginn, properAppointment.ende].map((elem) =>
      formatTaskDate(elem),
    );
  } else {
    formattedDate =
      properAppointment.ende !== undefined ? formatTaskDate(properAppointment.ende) : "-";
  }

  return {
    name: appointment.beschreibung,
    date: formattedDate,
    status: getAppointmentStatus(appointment),
    statusLabel: getAppointmentStatusLabel(appointment),
    result: appointmentResult ? appointmentResult.name : "-",
  };
}

/**
 * Returns proper datumsstatus or undefined
 * @param {object} appointment appointment to check
 * @param {datumsStatus} datumsStatus
 * @returns {string | undefined} datumsstatus.code
 */
export function getAppointmentType(appointment, datumsStatus) {
  const appointmentTypeCodesAndAssociatedDataProperty = {
    [datumsStatus.stattgefunden]: "stattgefundenerZeitraum", // 1000
    [datumsStatus.geplant]: "geplanterOderBerechneterZeitraum", // 1100
    [datumsStatus.berechnet]: "geplanterOderBerechneterZeitraum", // 1200
    [datumsStatus["initial prognostiziert"]]: "initialPrognostizierterZeitraum", // 1300
  };

  if (
    appointment?.datumsstatus?.code &&
    appointmentTypeCodesAndAssociatedDataProperty[appointment.datumsstatus.code] &&
    appointment[appointmentTypeCodesAndAssociatedDataProperty[appointment.datumsstatus.code]]
  ) {
    const dates =
      appointment[appointmentTypeCodesAndAssociatedDataProperty[appointment.datumsstatus.code]];

    if (dates.beginn || dates.ende) {
      return appointment.datumsstatus.code;
    }
  }

  return undefined;
}

/**
 * Returns the HEX color for the given appointment
 * @param {String} appointment appointment to check
 * @param datumsStatus
 * @returns {String} HEX color
 */
export function getAppointmentColor(appointment, datumsStatus) {
  let colorCode = "#6C6E72";

  if (
    appointment &&
    "verzoegert" in appointment &&
    "datumsstatus" in appointment &&
    appointment.datumsstatus &&
    Object.values(datumsStatus).includes(appointment.datumsstatus.code)
  ) {
    if (appointment.datumsstatus.code === datumsStatus.stattgefunden) {
      colorCode = "#00913D";
    } else if (appointment.datumsstatus.code === datumsStatus.geplant) {
      colorCode = "#04071A";
    }
  }

  return colorCode;
}

/**
 * Gets Monitoring State
 * @param {string} planID
 * @returns {Promise<boolean>}
 */
export function getMonitoringStateByPlanID(planID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .monitoringResourceApiFactory()
      .getMonitoringState(planID)
      .then((response) => {
        resolve(response.data.aktiv);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Get istVerzoegert state of new appointment data
 * @param {string} planID
 * @param {string} terminID
 * @param {string}  zeitraumBegin
 * @param {string|null}  zeitraumEnde
 * @param {boolean}  nachfolgendeNeuBerechnen
 * @param {boolean}  geplanteNeuBerechnen
 * @returns {Promise<boolean>} */
export function getIstVerzoegert(
  planID,
  terminID,
  zeitraumBegin,
  zeitraumEnde = null,
  nachfolgendeNeuBerechnen = false,
  geplanteNeuBerechnen = false,
) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .terminResourceApiFactory()
      .isVerzoegert(
        planID,
        terminID,
        zeitraumBegin,
        zeitraumEnde,
        nachfolgendeNeuBerechnen,
        geplanteNeuBerechnen,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

/**
 * Returns the correct date Object according to the datumsstatus
 * @param {object} appointment
 * @returns {object | undefined} relevant termin
 */
export function getCorrectDateForAppointment(appointment) {
  if (!appointment) return;

  const type = appointment?.datumsstatus?.code;
  const fundamentalStore = useFundamentalStore();
  const datumsStatusCodes = fundamentalStore.datumsStatusByName;
  let selectedDate;

  switch (type) {
    case datumsStatusCodes.stattgefunden:
      selectedDate = appointment.stattgefundenerZeitraum;
      break;
    case datumsStatusCodes["initial prognostiziert"]:
    case datumsStatusCodes.berechnet:
    case datumsStatusCodes.geplant:
      selectedDate = appointment.geplanterOderBerechneterZeitraum;
      break;
    default:
      selectedDate = appointment.geplanterOderBerechneterZeitraum || undefined;
  }
  return selectedDate;
}

/**
 * Initializes Schedule
 * @param {string} planID planID
 * @param {string} prognosisStartdate Startdatum vom VTS Grobabstimmung.
 * @returns Promise<ZeitplanungRest>
 */
export function initializeZeitplanung(planID, prognosisStartdate) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .terminResourceApiFactory()
      .initializeZeitplanung(planID, prognosisStartdate)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Delete user defined termin
 * @param {string} planID planID
 * @param {string} terminID
 * @param {boolean} [reCalculateFollowing]
 * @param {boolean} [recalculatePlanned]
 * @returns Promise<ZeitplanungRest>
 */
export function deleteCustomTermin(planID, terminID, reCalculateFollowing, recalculatePlanned) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .terminResourceApiFactory()
      .deleteTermin(planID, terminID, reCalculateFollowing, recalculatePlanned)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Returns true if at least one planned appointment exists after the given appointment
 * @param {object} scheduleRelevantData
 * @param {string} appointmentID terminID
 * @returns {boolean}
 */
export function hasFollowingPlannedAppointment(scheduleRelevantData, appointmentID) {
  if (!scheduleRelevantData || !appointmentID) return false;
  const appointmentIndex = scheduleRelevantData.termine.findIndex(
    (appointment) => appointment.terminID === appointmentID,
  );
  const followingAppointments = scheduleRelevantData.termine.slice(appointmentIndex + 1);

  return followingAppointments.some((appointment) => appointment?.datumsstatus?.geplant === true);
}

/**
 * Gets the current month name.
 * @returns {string} - The name of the current month.
 */
export function getMonthName(index) {
  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const adjustedIndex = index % monthNames.length;

  return monthNames[adjustedIndex].toUpperCase();
}

/**
 * Calculates the average position along the x-axis for the start of January and February based on the provided xScale.
 * @param xScale - The D3 timescale used to position dates along the x-axis.
 * @param currentYear
 * @returns {number} The calculated average position in pixels.
 */
export function datePosition(xScale, currentYear) {
  const januaryPosition = xScale(new Date(currentYear, 0, 1));
  const februaryPosition = xScale(new Date(currentYear, 1, 1));

  return (januaryPosition + februaryPosition) / 2;
}
