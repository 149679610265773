import { h } from "vue";
import { RouterView } from "vue-router";

import {
  GetAllCodelistenResolver,
  GetAllResolver,
  GetAllVerfahrenResolver,
  GetBlacklistResolver,
  GetNutzerVerfahrenResolver,
} from "@/router/services.js";

export const detailRoutes = {
  path: "/details/:planID/",
  component: () => import("@/views/VDetail.vue"),
  name: "DetailPage",
  props: true,
  meta: {
    resolve: {
      "/verfahren": GetAllVerfahrenResolver,
      "/nutzer/verfahren": GetNutzerVerfahrenResolver,
      "/konfigurationen/verfahren": GetAllResolver,
      "/konfigurationen/codelisten": GetAllCodelistenResolver,
      "/konfigurationen/blacklist": GetBlacklistResolver,
    },
  },
  children: [
    {
      path: "stammdaten",
      component: () => import("@/views/VDetailData.vue"),
      meta: {
        title: ["{proceedingName}", "Stammdaten"],
        label: "Stammdaten",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    {
      path: "teilnehmer",
      component: () => import("@/views/VDetailParticipants.vue"),
      meta: {
        title: ["Teilnehmer"],
        label: "Teilnehmer",
        requiredPermissions: ["ADMIN", "VERFAHREN_READ", "EIGENE_VERFAHREN_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
    },
    // Nest route and optional sub route both as children to workaround know bug
    // which did not set class "router-link-active" for aliases, sub routes, ...
    // See: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0028-router-active-link.md
    {
      path: ":vtsID/:roundID/:uvtsID",
      component: { render: () => h(RouterView) },
      meta: {
        title: ["Aufgaben"],
        label: "Aufgaben",
        isTasksView: true,
        requiredPermissions: ["ADMIN", "VERFAHRENSSTEUERUNG_READ", "VERFAHRENSSTEUERUNG_WRITE"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
          "/konfigurationen/blacklist": GetBlacklistResolver,
        },
      },
      children: [
        {
          path: "",
          component: () => import("@/views/VDetailTasks.vue"),
        },
        {
          path: ":taskID",
          component: () => import("@/views/VDetailTasks.vue"),
        },
      ],
    },
    {
      path: "dokumente",
      component: () => import("@/views/VDetailDocuments.vue"),
      meta: {
        title: ["{proceedingName}", "Dokumente"],
        label: "Dokumente",
        requiredPermissions: ["ADMIN", "DOKUMENT_READ", "EIGENE_DOKUMENT_READ"],
        resolve: {
          "/verfahren": GetAllVerfahrenResolver,
          "/konfigurationen/verfahren": GetAllResolver,
          "/konfigurationen/codelisten": GetAllCodelistenResolver,
          "/nutzer/verfahren": GetNutzerVerfahrenResolver,
        },
      },
    },
  ],
};
